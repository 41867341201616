/*
These functions make sure WordPress
and Foundation play nice together.
*/

// Mobile device array
var isMobile = {
    Android: function() { return navigator.userAgent.match(/Android/i); },
    BlackBerry: function() { return navigator.userAgent.match(/BlackBerry/i); },
    iOS: function() { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
    Opera: function() { return navigator.userAgent.match(/Opera Mini/i); },
    Windows: function() { return navigator.userAgent.match(/IEMobile/i); },
    any: function() { return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()); } };

jQuery(document).ready(function($) {

    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

    // Adds Flex Video to YouTube and Vimeo Embeds
    // jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    // 	if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
    // 	  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
    // 	} else {
    // 	  jQuery(this).wrap("<div class='responsive-embed'/>");
    // 	}
    // });

    // if( !isMobile.any() || jQuery(window).width() >= 1024 ) {
    // Pin header on scroll
    jQuery(window).scroll(function(){
        var content = '.content';
        var header = '.top-bar';
        var bottom = jQuery(content).offset().top;
        if (jQuery(this).scrollTop() > bottom){
            jQuery(header).addClass('pinned');
        }
        else{
            jQuery(header).removeClass('pinned');
        }
    });
    // }

    // Add ability to change SVG image fills
    jQuery('img.svg').each(function(){
        var jQueryimg = jQuery(this);
        var imgID = jQueryimg.attr('id');
        var imgClass = jQueryimg.attr('class');
        var imgURL = jQueryimg.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var jQuerysvg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                jQuerysvg = jQuerysvg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                jQuerysvg = jQuerysvg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            jQuerysvg = jQuerysvg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if(!jQuerysvg.attr('viewBox') && jQuerysvg.attr('height') && jQuerysvg.attr('width')) {
                jQuerysvg.attr('viewBox', '0 0 ' + jQuerysvg.attr('height') + ' ' + jQuerysvg.attr('width'));
            }

            // Replace image with new SVG
            jQueryimg.replaceWith(jQuerysvg);

        }, 'xml');
    });

    if( !isMobile.any() || jQuery(window).width() >= 1024 ) {
        // Add content bottom margin for parallax footer
        jQuery(".off-canvas-content").css('margin-bottom', jQuery('.footer').outerHeight(true));

        // Parallax background
        // jQuery(window).on('load scroll', function(){
        //     jQuery('.background-parallax').each(function(){
        //         var wScroll = jQuery(window).scrollTop()-jQuery(this).parent().offset().top+jQuery('#wpadminbar').height();
        //         jQuery(this).css('transform', 'translate(0px,' + wScroll + 'px)');
        //     });
        // });
    }

    // Open off-canvas footer when CTA menu link is clicked
    jQuery('.top-bar .menu .cta > a, .footer-cta-trigger a, .footer-cta-trigger').click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        jQuery('.off-canvas-contact').toggleClass('show');
    });

    // Mega menu over trigger
    // todo: remove redundancies
    var top_bar_li = jQuery( ".top-bar li" );
    var mega_posts = jQuery('#mega-posts');
    var mega_products = jQuery('#mega-products');

    jQuery( ".top-bar li.mega-posts, #mega-posts.is-mega-menu" ).mouseenter(function() {
        mega_posts.show();
    });

    top_bar_li.not('.mega-posts').mouseenter(function() {
        mega_posts.hide();
    });

    jQuery( ".top-bar li.mega-products, #mega-products.is-mega-menu" ).mouseenter(function() {
        mega_products.show();
    });

    top_bar_li.not('.mega-products').mouseenter(function() {
        mega_products.hide();
    });

    // Mega menu active product toggle
    jQuery('ul.product-list > li').mouseenter(function () {
        jQuery(this).addClass('active').siblings().removeClass('active');
        var active_cat = jQuery(this).attr('id');
        jQuery( "ul.products li").each(function() {
            if( jQuery(this).hasClass( active_cat ) ) {
                jQuery(this).show();
            } else {
                jQuery(this).hide();
            }
        });
    });

    jQuery( "ul.products li").each(function() {
        var active_cat = jQuery('ul.product-list > li.active').attr('id');
        jQuery("li."+active_cat).show();
    });

    // Scroll functions
    jQuery(window).scroll(function(){
        // Fade in/out scroll to top button
        if(jQuery(this).scrollTop() > 100){
            jQuery('#scroll').fadeIn();
        } else{
            jQuery('#scroll').fadeOut();
        }

        // Close mega menus if open
        mega_posts.hide();
        mega_products.hide();
    });

    jQuery('#scroll').click(function(){
        jQuery("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });

    // Hide/show sidebar menu arrow when bottom of page is reached
    jQuery(window).on("scroll", function() {
        var scrollHeight = jQuery(document).height();
        var scrollPosition = jQuery(window).height() + jQuery(window).scrollTop();
        if ((scrollHeight - scrollPosition) / scrollHeight <= '.12') {
            jQuery('.top-bar').addClass('leave');
        } else {
            jQuery('.top-bar').removeClass('leave');
        }
    });

    // Load more posts when clicked
    jQuery('#load-more h3').click(function(){

        var div = jQuery(this).parent(),
            button = jQuery(this),
            data = {
                'action': 'loadmore',
                'query': load_more_parms.posts, // that's how we get params from wp_localize_script() function
                'page' : load_more_parms.current_page
            };

        jQuery.ajax({
            url : load_more_parms.ajaxurl, // AJAX handler
            data : data,
            type : 'POST',
            beforeSend : function ( xhr ) {
                button.text('Loading...'); // change the button text, you can also add a preloader image
            },
            success : function( data ){
                if( data ) {
                    button.text( 'Load More' );
                    div.before(data); // insert new posts

                    // todo: reinitialize mixitup
                    // mixer.destroy();

                    load_more_parms.current_page++;

                    if ( load_more_parms.current_page == load_more_parms.max_page )
                        div.remove(); // if last page, remove the button
                } else {
                    div.remove(); // if no data, remove the button as well
                }
            }
        });
    });

    // Select all links with hashes
    // Remove links that don't actually link to anything
    // todo: fix for accordions
    // $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
    //     // On-page links
    //     if (
    //         location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname
    //     ) {
    //         // Figure out element to scroll to
    //         var target = $(this.hash);
    //         target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    //         // Does a scroll target exist?
    //         if (target.length) {
    //             // Only prevent default if animation is actually gonna happen
    //             event.preventDefault();
    //             $('html, body').animate({
    //                 scrollTop: target.offset().top
    //             }, 1000, function() {
    //                 // Callback after animation
    //                 // Must change focus!
    //                 var $target = $(target);
    //                 $target.focus();
    //                 if ($target.is(":focus")) { // Checking if the target was focused
    //                     return false;
    //                 } else {
    //                     $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
    //                     $target.focus(); // Set focus again
    //                 }
    //             });
    //         }
    //     }
    // });
});

// Loader
jQuery(window).load(function() {
    jQuery( "#loader" ).fadeOut( "slow", function() {
        // jQuery( "body" ).removeClass( "no-scroll" );
    });
});

// function to set the height on fly
function autoHeight() {
    // Parallax footer
    var content = jQuery('.off-canvas-content');
    content.css('min-height', 0);
    content.css('min-height', (
        jQuery(window).outerHeight(true)
    ));

    // Position mega menu below top-bar
    var top_bar = jQuery('.top-bar');
    var mega = jQuery('.is-mega-menu');
    mega.css('top', ( top_bar.outerHeight(true) ));
}

// onDocumentReady function bind
jQuery(document).ready(function() {
    autoHeight();
});

// onResize bind of the function
jQuery(window).resize(function() {
    autoHeight();
});